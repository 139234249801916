@font-face {
  font-family: 'customfont';
  src:
    url('/theme/US/site_1/fonts/customfont.ttf?rg9kri') format('truetype'),
    url('/theme/US/site_1/fonts/customfont.woff?rg9kri') format('woff'),
    url('/theme/US/site_1/fonts/customfont.svg?rg9kri#customfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mdl-layout .fa-twitter:before {content: "\e900"!important; font-family: customfont !important; }

